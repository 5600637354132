<template>
	<v-sheet class="asset" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle">
										<ShowValue :object="asset" object-key="name" label="asset name"></ShowValue>
									</b>
									<v-chip color="cyan" class="ml-2 mr-2" label text-color="white"
										>{{ asset.barcode }}
									</v-chip>
									<template v-if="asset.retired == 1">
										<v-chip color="#F44336" text-color="white" label> RETIRED </v-chip>
									</template>
									<template v-else>
										<template v-if="asset.activated == 0">
											<v-chip color="#F44336" text-color="white" label> INACTIVE </v-chip>
										</template>
										<template v-else>
											<template v-if="asset.activated == 1">
												<v-chip color="#4CAF50" text-color="white" label> ACTIVE </v-chip>
											</template>
											<template v-if="asset.has_service == 0">
												<template v-if="asset.has_checkout == 1">
													<v-chip class="ml-2" color="orange" text-color="white" label> ISSUED </v-chip>
												</template>
												<template v-else>
													<v-chip class="ml-2" color="green" text-color="white" label> READY FOR ISSUE </v-chip>
												</template>
											</template>
										</template>
									</template>
								</span>
							</p>
						</v-flex>
						<v-flex :class="`text-right ${deviceWidth > 1360.02 ? 'md6' : 'md4'}`">
							<template v-if="asset.retired">
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
										>
											<v-icon small left>mdi-more mdi-flip-h</v-icon>More
										</v-btn>
									</template>

									<v-list>
										<v-list-item v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-btn
									v-if="false"
									v-on:click="doAction('print-label')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
								>
									<v-icon small left>mdi-printer</v-icon>Print Label
								</v-btn>
							</template>
							<template v-else>
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
										>
											<v-icon small left>mdi-more mdi-flip-h</v-icon>More
										</v-btn>
									</template>

									<v-list>
										<v-list-item v-if="getPermission('asset:update')" v-on:click="doAction('edit')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('asset:create')" v-on:click="doAction('duplicate')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-content-duplicate</v-icon>
												Clone
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('asset:delete')" v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
										<!-- This is used in responsive -->
										<template v-if="deviceWidth < 1359.98">
											<template v-if="asset.has_checkout">
												<v-list-item
													v-if="getPermission('checkout:update')"
													v-on:click="doAction('extend-checkout')"
												>
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-share-all-outline</v-icon>
														Extend Issue
													</v-list-item-title>
												</v-list-item>
												<v-list-item v-if="getPermission('checkin:create')" v-on:click="doAction('checkin')">
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-share-outline mdi-flip-h</v-icon>
														Receive
													</v-list-item-title>
												</v-list-item>
											</template>
											<template v-else>
												<template v-if="!asset.has_service">
													<v-list-item v-if="getPermission('checkout:create')" v-on:click="doAction('checkout')">
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-share-outline</v-icon>
															Issue
														</v-list-item-title>
													</v-list-item>
													<v-list-item
														v-if="getPermission('reservation:create')"
														v-on:click="doAction('reserve')"
													>
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-calendar-check</v-icon>
															Reserve
														</v-list-item-title>
													</v-list-item>
												</template>
												<template v-if="!asset.has_checkout">
													<v-list-item v-if="getPermission('asset:update')" v-on:click="doAction('retire')">
														<v-list-item-title>
															<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
															Retire
														</v-list-item-title>
													</v-list-item>
												</template>
											</template>
										</template>
									</v-list>
								</v-menu>

								<v-menu
									v-if="getPermission('asset:cretae')"
									offset-y
									left
									bottom
									content-class="elevation-3"
									rounded="0"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-hammer-wrench</v-icon>Service
										</v-btn>
									</template>

									<v-list>
										<v-list-item v-on:click="doAction('start-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-clock-check-outline</v-icon>
												Start
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="asset.service_uuid" v-on:click="doAction('complete-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-format-list-checks</v-icon>
												Complete
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="asset.service_uuid" v-on:click="doAction('extend-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-share-all-outline</v-icon>
												Extend
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-on:click="doAction('schedule-service')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-calendar-clock</v-icon>
												Schedule
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<template v-if="deviceWidth > 1360.02">
									<template v-if="asset.has_checkout">
										<v-btn
											v-if="getPermission('checkout:update')"
											v-on:click="doAction('extend-checkout')"
											color="blue darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-all-outline</v-icon>Extend Issue
										</v-btn>
										<v-btn
											v-if="getPermission('checkin:create')"
											v-on:click="doAction('checkin')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-outline mdi-flip-h</v-icon>Receive
										</v-btn>
									</template>
									<template v-else>
										<template v-if="!asset.has_service">
											<v-btn
												v-if="getPermission('checkout:create')"
												v-on:click="doAction('checkout')"
												color="blue darken-4 text-white"
												class="mx-2"
												depressed
												tile
											>
												<v-icon small left>mdi-share-outline</v-icon>Issue
											</v-btn>
											<v-btn
												v-if="getPermission('reservation:create')"
												v-on:click="doAction('reserve')"
												color="blue darken-4 text-white"
												class="ml-2"
												depressed
												tile
											>
												<v-icon small left>mdi-calendar-check</v-icon>Reserve
											</v-btn>
										</template>
										<template v-if="!asset.has_checkout">
											<v-btn
												v-if="getPermission('asset:update')"
												v-on:click="doAction('retire')"
												color="blue darken-4 text-white"
												class="ml-2"
												depressed
												tile
											>
												<v-icon small left>mdi-cancel</v-icon>Retire
											</v-btn>
										</template>
									</template>
								</template>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="asset.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div v-if="asset.pico_barcode && asset.barcode_image" style="max-width: 300px">
								<div class="d-inline-block text-center">
									<img
										style="max-width: 100%; height: 30px"
										:src="asset.barcode_image"
										:alt="asset.pico_barcode"
									/>
									<pre class="mb-0 barcode-text bold-700">{{ asset.pico_barcode }}</pre>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Asset # : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="barcode" label="asset #"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Receive Due : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="checkout_on_formatted" label="check-in due">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600"> Contact for Item : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="contact" label="contact for item"> </ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600 mr-2">Supplier : </span>
										<span class="fw-500">
											<span class="fw-500 no-wrap">
												<ShowValue
													:object="asset"
													object-key="supplier_relation.display_name"
													label="supplier"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2" v-if="false">
									<div class="mt-2">
										<span class="fw-600 mr-2">Incharge Officer : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="asset"
												object-key="member_relation.display_name"
												label="incharge-officer"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600"> Category : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="group_relation.name" label="Category">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Purchased On : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="purchased_on_formatted" label="purchased on">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Cost Price : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="cost_price_formatted" label="cost price">
											</ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33 pl-2">
									<div class="mt-2">
										<span class="fw-600">Barcode : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="pico_barcode" label="barcode"> </ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="false" class="w-20 pr-3">
							<template v-if="asset.pico_barcode && asset.barcode_image">
								<span class="fw-600">Pico Barcode : </span>
								<span class="fw-500">
									<Barcode circle :src="asset.barcode_image"></Barcode>
								</span>
							</template>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="assetTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details
					</v-tab>
					<v-tab v-if="getPermission('service:view')" href="#service">
						<v-icon small left>mdi-hammer-wrench</v-icon> Services
					</v-tab>
					<v-tab v-if="getPermission('checkout:view')" href="#checked-out">
						<v-icon small left>mdi-share-outline</v-icon> Issued
					</v-tab>
					<v-tab v-if="getPermission('reservation:view')" href="#reservation">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations
					</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs </v-tab>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders
					</v-tab>
					<v-tab v-if="getPermission('asset:update')" href="#file">
						<v-icon small left>mdi-file-document-multiple</v-icon> Files
					</v-tab>
					<v-tab v-if="getPermission('asset:update')" href="#service_report">
						<v-icon small left>mdi-file-document-multiple</v-icon> Service Report
					</v-tab>
					<v-tab v-if="getPermission('asset:update')" href="#comment">
						<v-icon small left>mdi-comment-multiple</v-icon> Comments
					</v-tab>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="assetTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Asset Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<!-- <th width="250px">Receive Due</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="checkout_on_formatted"
												label="check-in due"
											></ShowValue>
										</td> -->
										<template v-if="false">
											<th width="250px">
												Age
												<TooltipQuestion>
													<template v-slot:text>Age is calculated based on the purchase date</template>
												</TooltipQuestion>
											</th>
											<td>
												<ShowValue :object="asset" object-key="age" label="age"></ShowValue>
											</td>
										</template>
									</tr>
									<tr v-if="false">
										<th width="250px">Sub Group</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="sub_group_relation.name"
												label="sub group"
											></ShowValue>
										</td>
										<th width="250px">Depreciated Value</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="depreciated_value"
												label="depreciated value"
											></ShowValue>
										</td>
									</tr>
									<tr v-if="false">
										<th width="250px">Last Service Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="last_service_date"
												label="last service date"
											></ShowValue>
										</td>
										<th width="250px">Upcoming Service Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="upcoming_service_date"
												label="upcoming service date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Warranty Start Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="warranty_start_date_formatted"
												label="warranty Start Date"
											></ShowValue>
										</td>
										<th width="250px">Warranty End Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="warranty_end_date_formatted"
												label="warranty End Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Warranty Reminder Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="warranty_reminder_date_formatted"
												label="warranty Reminder Date"
											></ShowValue>
										</td>
										<th width="250px">Maintenance Start Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="maintenance_start_date_formatted"
												label="Maintenance Start Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Maintenance End Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="maintenance_end_date_formatted"
												label="Maintenance End Date"
											></ShowValue>
										</td>
										<th width="250px">Maintenance Reminder Date</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="maintenance_reminder_date_formatted"
												label="Maintenance Reminder Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Maintenance By</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="maintenance_by_relation.display_name"
												label="Maintenance By"
											></ShowValue>
										</td>
										<th width="250px">Cost of Maintenance</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="maintenance_cost_formatted"
												label="Cost of Maintenance"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Created On</th>
										<td colspan="3">
											<ShowValue
												:object="asset"
												object-key="created_at"
												tooltip
												tooltip-value="added_at_formatted"
												label="created on"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Description</th>
										<td colspan="3">
											<ShowValue :object="asset" object-key="description" label="description"> </ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Remark</th>
										<td colspan="3">
											<ShowValue :object="asset" object-key="remark" label="remark"> </ShowValue>
										</td>
									</tr>
									<tr v-if="false">
										<th width="250px">Location</th>
										<td colspan="3">
											<ShowValue
												:object="asset"
												object-key="location_relation.name"
												label="location"
											></ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="service">
						<AssetServiceListing v-if="assetTab == 'service'" class="mx-4" :asset-uuid="uuid">
						</AssetServiceListing>
					</v-tab-item>
					<v-tab-item value="checked-out">
						<ReservationListing
							v-if="assetTab == 'checked-out'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
							status="checked-out"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="reservation">
						<ReservationListing
							v-if="assetTab == 'reservation'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
							status="reserved"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="assetTab == 'event'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item v-if="false" value="work-order">
						<WorkOrderListing
							v-if="assetTab == 'work-order'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="assetTab == 'file'"
							class="mx-4"
							type-text="Asset Files"
							type="asset"
							:type-uuid="uuid"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="service_report">
						<Files
							v-if="assetTab == 'service_report'"
							class="mx-4"
							type-text="Asset Service Report"
							type="service-report"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							v-if="assetTab == 'comment'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support">
						<SupportTemplate
							v-if="assetTab == 'support'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Asset"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!asset.retired ? 'To preserve associated records, you could retire the Asset instead.' : null
				"
			>
				<template v-if="!asset.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<RetireTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="goBack()"
				v-on:close="retireDialog = false"
				:retire-dialog="retireDialog"
			></RetireTemplate>
			<ExtendCheckoutTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				:checkout-uuid="asset.checkout_uuid"
				v-on:success="
					extendCheckoutDialog = false;
					getAsset();
				"
				v-on:close="extendCheckoutDialog = false"
				:extend-checkout-dialog="extendCheckoutDialog"
			>
			</ExtendCheckoutTemplate>
			<CheckoutTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					checkoutDialog = false;
					getAsset();
				"
				v-on:close="checkoutDialog = false"
				:checkout-dialog="checkoutDialog"
			></CheckoutTemplate>
			<template v-if="checkinDialog && uuid && asset && asset.checkout_uuid">
				<CheckinTemplate
					type="Asset"
					endpoint="asset"
					:type-uuid="uuid"
					:checkout-uuid="asset.checkout_uuid"
					v-on:success="
						checkinDialog = false;
						getAsset();
					"
					v-on:close="checkinDialog = false"
					:checkin-dialog="checkinDialog"
				></CheckinTemplate>
			</template>
			<ReservationTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					reservationDialog = false;
					getAsset();
				"
				v-on:close="reservationDialog = false"
				:reservation-dialog="reservationDialog"
			></ReservationTemplate>
			<ServiceStartTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					serviceDialog = false;
					getAsset();
				"
				v-on:close="serviceDialog = false"
				:service-dialog="serviceDialog"
			></ServiceStartTemplate>
			<ServiceScheduleTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				v-on:success="
					serviceScheduleDialog = false;
					getAsset();
				"
				v-on:close="serviceScheduleDialog = false"
				:service-dialog="serviceScheduleDialog"
			></ServiceScheduleTemplate>
			<ServiceCompleteTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				:service-uuid="asset.service_uuid"
				v-on:success="
					serviceCompleteDialog = false;
					getServices();
					getAsset();
				"
				v-on:close="serviceCompleteDialog = false"
				:service-dialog="serviceCompleteDialog"
			></ServiceCompleteTemplate>
			<ServiceExtendTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="uuid"
				:service-uuid="asset.service_uuid"
				v-on:success="
					serviceExtendDialog = false;
					getServices();
					getAsset();
				"
				v-on:close="serviceExtendDialog = false"
				:service-dialog="serviceExtendDialog"
			></ServiceExtendTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import RetireTemplate from "@/view/components/RetireTemplate";
import CheckinTemplate from "@/view/components/CheckinTemplate";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import ExtendCheckoutTemplate from "@/view/components/ExtendCheckoutTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import ServiceStartTemplate from "@/view/components/ServiceStartTemplate";
import ServiceScheduleTemplate from "@/view/components/ServiceScheduleTemplate";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import ReservationListing from "@/view/components/ReservationListing";
import EventListing from "@/view/components/EventListing";
import AssetServiceListing from "@/view/components/AssetServiceListing";
import { GetAsset } from "@/core/lib/asset.lib";
import ServiceCompleteTemplate from "@/view/components/ServiceCompleteTemplate";
import ServiceExtendTemplate from "@/view/components/ServiceExtendTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "asset-detail",
	title: "Detail Asset",
	data() {
		return {
			extendCheckoutDialog: false,
			checkinDialog: false,
			serviceDialog: false,
			reservationDialog: false,
			serviceScheduleDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			checkoutDialog: false,
			retireDialog: false,
			deleteDialog: false,
			deleteURL: null,
			deleteText: null,
			assetTab: null,
			uuid: null,
			Barcode: null,
			contentLoaded: false,
			pageLoading: true,
			asset: {},
			deviceWidth: document.documentElement.clientWidth,
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "asset-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "asset-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
				case "print-label":
					break;
				case "checkout-history":
					break;
				case "verification-history":
					break;
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "reserve":
					this.reservationDialog = true;
					break;
				case "extend-checkout":
					this.extendCheckoutDialog = true;
					break;
				case "checkin":
					this.checkinDialog = true;
					break;
				case "start-service":
					this.serviceDialog = true;
					break;
				case "schedule-service":
					this.serviceScheduleDialog = true;
					break;
				case "complete-service":
					this.serviceCompleteDialog = true;
					break;
				case "extend-service":
					this.serviceExtendDialog = true;
					break;
			}
		},
		getServices() {
			EventBus.$emit("reload:asset-service", true);
		},
		getAsset() {
			GetAsset(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset = data;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `asset/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		ShowValue,
		Comments,
		DeleteTemplate,
		RetireTemplate,
		CheckinTemplate,
		CheckoutTemplate,
		ExtendCheckoutTemplate,
		ServiceStartTemplate,
		ServiceScheduleTemplate,
		ReservationListing,
		AssetServiceListing,
		ServiceCompleteTemplate,
		SupportTemplate,
		ServiceExtendTemplate,
		WorkOrderListing,
		EventListing,
		ReservationTemplate,
		TooltipQuestion,
		ImageTemplate,
		Barcode,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getAsset();

		EventBus.$on("reload:asset", () => {
			this.getAsset();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
